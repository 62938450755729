import types from "./types";
import * as moment from 'moment';

const initialState = {
    days: [],
    times:[],
    schedule: {},
    rescheduled: false,
    spinning: false
};

export const schedulerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CAPACITIES: {
            const { schedules } = action.payload;
            const days = schedules.map(item => {
                const times = item.times.map(time => {return {...time,
                    selected: false
                }});

                return {...item,
                    times,
                    selected: false
                }
            });

            return {
                ...state,
                days,
                times: []
            };
        }
        case types.SELECT_DAY: {
            const { date } = action.payload;
            const { days } = state;
            const number = parseInt(moment(date).format('D'));

            let dayList = days.map(item => {
                if(item.number === number) {
                    return {...item, selected: true}
                } else {
                    return {...item, selected: false}
                }
            });
            let times = dayList.find(day => Boolean(day.selected) === true).times.filter(time => Boolean(time.available) === true);

            if (times.length > 0) {
                times.splice(0, 1, {...times[0], selected: true});
            }

            return {
                ...state,
                days: dayList,
                times,
                schedule: {
                    date,
                    name: times.length > 0 ? times[0].name : ""
                }
            };
        }
        case types.SELECT_TIME: {
            const name = action.payload;
            const times = state.times.map(item => {
                if(item.name === name) {
                    return {...item,
                        selected: true,
                        disabled: false}
                } else {
                    return {...item,
                        selected: false,
                        disabled: true}
                }
            });

            return {
                ...state,
                times,
                schedule: {...state.schedule, name}
            };
        }

        case types.RESCHEDULED: {
            return {
                ...state,
                rescheduled: true
            };
        }

        case types.SHOW_SPINNER: {
            return {
                ...state,
                spinning: true
            };
        }
        case types.HIDE_SPINNER: {
            return {
                ...state,
                spinning: false
            };
        }

        default:
            return state;
    }
};
