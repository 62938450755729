import React from 'react';
import {Spinner} from "reactstrap";

const SpinnerComponent = () => (
    <div className="spinner-div">
        <Spinner color="primary" className="spinner" />
    </div>
);

export default SpinnerComponent;
