import React, {Component} from 'react';
import * as moment from 'moment';
import TimeSlotsComponent from "./timeslots/TimeSlotsComponent";
import DatePicker from "react-datepicker";
import Spinner from './../common/spinner';
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import config from './../../config';
import { Row, Col, Button } from 'reactstrap';

// Initialize dynamic days
let preselectedDay = 0;
let addedDays = 1;
let daysToCheck = 14;
let startDate = moment().add(addedDays, 'day').format('YYYY-MM-DD');
let smartinsureRescheduleDays = 7;

class SchedulerComponent extends Component {

    componentDidMount() {
        const { location } = this.props;
        const values = queryString.parse(location.search);

        // This block can be removed after 12-17-2019
        if (values.clientName === 'D2C - Ring') {
            let earliestStartDate = '2019-12-17';
            if (moment(startDate).isBefore(moment(earliestStartDate))) {
                startDate = earliestStartDate;
                preselectedDay = 13;
            }
        }

        if (values.clientName === 'D2C - SmartInsure') {
            let originalOrderDate = values.createdDate.slice(0,10);
            startDate = moment(originalOrderDate).add(smartinsureRescheduleDays, 'day').format( 'YYYY-MM-DD');
            preselectedDay = 5;
        }

        this.props.getCapacities(
            startDate,
            values.zipCode,
            values.variantIds,
            values.clientName
        );
    }

    onDateSelect = (date) => {
        const { selectDay } = this.props;
        selectDay(date);
    };

    onSubmit = () => {
        const { selectedDate, selectedTime, reschedule, location, addErrorMessage } = this.props;
        const values = queryString.parse(location.search);

        if (!values.clientOrderGuid) {
            addErrorMessage("Order id is not present");
            return;
        }

        if (!selectedDate) {
            addErrorMessage("Date is not selected");
            return;
        }

        if (!selectedTime) {
            addErrorMessage("Time is not selected");
            return;
        }

        reschedule(values.clientOrderGuid, moment(selectedDate).format( 'YYYY-MM-DD'), selectedTime);
    };

    render() {

        console.log("SCHEDULER PROPS>>>", this.props)
        if (!(this.props.days && this.props.days.length > 0)) {
            return "";
        }

        const { selectedDate, selectTime, rescheduled, spinning } = this.props;

        if (rescheduled) {
            window.location = config.api.SHOPIFY_ACCOUNT_PAGE;
        }

        return (
            <div className="container mt-5 mb-3">
                <div className="row mb-12">
                    <div className="col-12 col-md-12 text-center-respo">
                        <h1>Reschedule Your Service</h1>
                        <p className="text-muted">Please note that these service windows reflect real time availability of technicians in your area.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-3 text-center-respo">
                        <DatePicker
                            inline
                            minDate={moment(startDate).utc().toDate()}
                            maxDate={moment(startDate).add(daysToCheck - 1, "day").utc().toDate()}
                            selected={selectedDate ? selectedDate : moment().add(preselectedDay, 'day').utc().toDate()}
                            onChange={this.onDateSelect} />
                    </div>
                    <div className="col-12 col-md-4 text-center-respo">
                        <p className="date-selected">{selectedDate ? moment(selectedDate).format('MMMM DD, YYYY') : ""}</p>
                        {selectedDate
                            ? <TimeSlotsComponent times={this.props.times} onSelect={selectTime} />
                            : ""
                        }
                    </div>
                    <div className="col-12 col-md-5">
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-12">
                        <hr className="margin-top-10"/>
                    </div>
                </div>

                <Row className="mb-12">
                    <Col className="col-md-auto">
                        {spinning &&
                        <Spinner />
                        }
                        {!spinning &&
                        <Button color="primary" className="btn btn-primary" onClick={this.onSubmit}>Reschedule</Button>
                        }
                    </Col>
                    <Col className="col-md-auto">
                        <Button color="link" className="fs-16 mb-3 d-block blue link" href={config.api.SHOPIFY_ACCOUNT_PAGE}>Cancel</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SchedulerComponent;
